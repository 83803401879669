import * as React from "react";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/service-order/new");
  });
  return null;
};
export default Home;
