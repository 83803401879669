export const getEnv = (): string => {
  if (window.location.origin.includes("dev")) {
    return "dev";
  }
  if (window.location.origin.includes("stage")) {
    return "stage";
  }
  if (
    window.location.origin.includes("local") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return "local";
  }
  if (window.location.origin.includes("test")) {
    return "test";
  }
  return "prod";
};

export function toIsoDate(date: Date): string {
  const str = date.toISOString();
  return str.slice(0, str.indexOf("T"));
}

export const parseDate = (date: string | undefined): Date => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

export function formatDate(
  value: string | number | Date | null | undefined
): string {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;
  const lang = (document.documentElement as HTMLElement).lang;
  return new Intl.DateTimeFormat(lang, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
}

export function formatDateTime(
  value: string | number | Date | null | undefined
): string {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;
  const lang = (document.documentElement as HTMLElement).lang;
  return new Intl.DateTimeFormat(lang, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
}

export function formatTime(
  value: string | number | Date | null | undefined
): string {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;
  const lang = (document.documentElement as HTMLElement).lang;
  return new Intl.DateTimeFormat(lang, {
    hour: "numeric",
    minute: "numeric",
  }).format(date);
}
