import { Flex } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useLoginUsername from "../../api/auth/useLoginUsername";
import NoStyleButton from "../Buttons/NoStyleButton";
import Loader from "../Loader/Loader";
import RecoverModal from "./RecoverModal";

interface Props {
  setError: (error: string) => void;
}

const LoginUsername: React.FC<Props> = ({ setError }) => {
  const { t } = useTranslation();
  const { loginUsername, isPending } = useLoginUsername();
  const [state, setState] = React.useState<{
    showRecover: boolean;
    username: string;
    password: string;
    rememberMe: boolean;
  }>({ password: "", username: "", showRecover: false, rememberMe: false });
  return (
    <form>
      <div className="flow-input-container">
        <label htmlFor="username">{t("module.login.username")}</label>
        <input
          style={{ width: "220px" }}
          type="text"
          id="username"
          name="username"
          autoComplete={"username"}
          value={state.username}
          onChange={(e) => setState({ ...state, username: e.target.value })}
        />
      </div>
      <div className="flow-input-container">
        <label htmlFor="password">{t("module.login.password")}</label>
        <input
          style={{ width: "220px" }}
          type="password"
          id="password"
          name="password"
          autoComplete={"current-password"}
          value={state.password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />
      </div>
      <div className="buttons">
        <label className="remember-forgot">
          <input
            type="checkbox"
            id="remember_me"
            name="remember_me"
            onChange={(e) =>
              setState({ ...state, rememberMe: e.target.checked })
            }
            checked={state.rememberMe}
          />
          <span className="remember">{t("module.login.remember.me")}</span>
          <span className="separator">&middot;</span>
          <NoStyleButton
            type="button"
            className="forgot"
            onClick={(e) => {
              e.preventDefault();
              setState({ ...state, showRecover: true });
            }}
          >
            {t("module.recover")}
          </NoStyleButton>
        </label>
        <Flex alignItems={"center"}>
          <button
            type="submit"
            className="btn"
            disabled={!(state.password && state.username) || isPending}
            onClick={async (e) => {
              e.preventDefault();
              const loginError = await loginUsername({
                username: state.username,
                password: state.password,
                rememberMe: state.rememberMe,
              });
              setError(loginError ? "module.login.invalid.user" : "");
            }}
          >
            {t("module.login.login")}
          </button>
          <Loader isLoading={isPending} />
        </Flex>
      </div>
      {state.showRecover && (
        <RecoverModal
          onClose={() => setState({ ...state, showRecover: false })}
        />
      )}
    </form>
  );
};
export default LoginUsername;
