import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export interface SendOtpModel {
  timeout: number;
  error: string;
}

const post = async () => {
  const { data } = await axios.post<SendOtpModel>("/api/otp");
  return data;
};

const useSendOtp = () => {
  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  return { sendOtp: mutateAsync, isPending };
};
export default useSendOtp;
