import { AxiosError } from "axios";
import * as React from "react";
import ErrorPage from "../../Routes/Error/ErrorPage";

interface State {
  status: number;
  message: string;
}

class ErrorHandler extends React.PureComponent<React.PropsWithChildren, State> {
  public state: State = {
    status: 0,
    message: "",
  };

  public static getDerivedStateFromError(error: AxiosError | Error): State {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorData = axiosError.response?.data;
      let message = "";
      if (errorData) {
        const obj = errorData as { reference: string };
        if (obj.reference) {
          message = obj.reference;
        }
      }
      return {
        status: axiosError?.request?.status || 500,
        message: message,
      };
    }
    return { status: -1, message: error.message };
  }

  public render(): React.ReactNode {
    if (this.state.status) {
      return (
        <ErrorPage status={this.state.status} message={this.state.message} />
      );
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorHandler;

const isAxiosError = (error: Error): boolean => {
  return (error as AxiosError).isAxiosError;
};
