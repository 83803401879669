import { init as initApm } from "@elastic/apm-rum";
import * as React from "react";
import { getEnv } from "../utils/utils";
import { useAcceptStatistics } from "./useCookies";

export const useApm = (): void => {
  const acceptStatistics = useAcceptStatistics();
  const [, setPrevAccepted] = React.useState<boolean>(acceptStatistics);
  React.useEffect(() => {
    if (acceptStatistics) {
      const environment = getEnv();
      const serverUrl = getUrl(environment);
      initApm({
        active: "dev,stage,prod".includes(environment),
        serviceName: "tso-integrator-ui",
        serverUrl: serverUrl,
        environment: environment,
      });
    }
    setPrevAccepted((prevValue) => {
      if (acceptStatistics !== prevValue) {
        window.location.reload();
      }
      return acceptStatistics;
    });
  }, [acceptStatistics]);
};
export default useApm;

const getUrl = (environment: string): string => {
  if ("prod".includes(environment)) {
    return "https://apm-proxy-tso.b2b.tele2.se/";
  }
  return "https://apm-proxy-ci1-tso.b2b-test.tele2.se/";
};
