import * as React from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import Login from "../Login/Login";

interface AuthenticatedRouteProps {
  element: React.ReactElement;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ element }) => {
  const { authenticated } = React.useContext(SessionContext);
  if (!authenticated) {
    return <Login />;
  }
  return <>{element}</>;
};
export default AuthenticatedRoute;
