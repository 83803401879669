import * as React from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Contexts/LayoutContext";
import { NavigationContext } from "../../Contexts/NavigationContext";

const Breadcrumbs: React.FC = () => {
  const { layoutState } = React.useContext(LayoutContext);
  const { navigate } = React.useContext(NavigationContext);
  const { t } = useTranslation();
  if (layoutState.breadLinks.length === 0) {
    return null;
  }
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {layoutState.breadLinks.map((link, index) => (
          <li
            key={link}
            className={`breadcrumb-item ${
              index === layoutState.breadLinks.length - 1 ? "active" : ""
            }`}
            aria-current={index === layoutState.breadLinks.length - 1}
          >
            {index === layoutState.breadLinks.length - 1 ? (
              <>{t(layoutState.breadTexts[index])}</>
            ) : (
              <a
                href={link}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(link);
                }}
              >
                {t(layoutState.breadTexts[index])}
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
export default Breadcrumbs;
