import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

export interface SessionModel {
  timestamp: number;
  version: string;
  user: string;
  roles: string[];
}

const get = async () => {
  const { data } = await axios.get<SessionModel>("/api/session");
  return data;
};

const QUERY_KEY = "session-data";
const useSession = () =>
  useQuery({
    queryFn: get,
    queryKey: [QUERY_KEY],
    refetchInterval: 300_000,
  });
export default useSession;

export const useInvalidateSession = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

export const useRemoveAllData = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    queryClient.removeQueries({
      predicate: (query) =>
        !query.queryKey.find((value) => value === QUERY_KEY),
    });
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
