import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useInvalidateSession } from "../session/useSession";

const post = async (params: { otpCode: string }) => {
  const formData = new FormData();
  formData.set("otp", params.otpCode);
  await axios.post("/api/otp/security_check", formData);
};

const useLoginOtp = () => {
  const invalidateSession = useInvalidateSession();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: post,
    throwOnError: false,
  });
  const loginOtp = useCallback(
    async (params: { otpCode: string }) => {
      try {
        await mutateAsync(params);
        await invalidateSession();
        return false;
      } catch (error) {
        return true;
      }
    },
    [invalidateSession, mutateAsync]
  );

  return { loginOtp, isPending };
};
export default useLoginOtp;
