import * as React from "react";

interface Props {
  isLoading: boolean;
}

const Loader: React.FC<Props> = ({ isLoading }) => {
  return (
    <span
      style={{ margin: "4px" }}
      className={`ajax-loader ${isLoading ? "spin" : ""}`}
    />
  );
};
export default Loader;
