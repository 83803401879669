import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LayoutContext } from "../../Contexts/LayoutContext";
import { SessionContext } from "../../Contexts/SessionContext";
import LoginInfo from "./LoginInfo";
import LoginOtp from "./LoginOtp";
import LoginUsername from "./LoginUsername";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = React.useState("");
  const { setLayoutState } = React.useContext(LayoutContext);
  const { roles } = React.useContext(SessionContext);
  React.useEffect(() => {
    setLayoutState({
      breadLinks: [],
      breadTexts: [],
      classNames: ["main-login"],
      title: `${t("module.login")} - ${t("site.name")}`,
    });
  }, [setLayoutState, t]);
  return (
    <div className="main-body">
      <div className="main-content">
        <h1>{t("site.name")}</h1>
        {error && <div className="alert alert-warning">{t(error)}</div>}
        <LoginContainer className="box clearfix">
          <div style={{ width: "50%" }}>
            {roles.length === 0 && <LoginUsername setError={setError} />}
            {roles.includes("ROLE_REQUIRE_MFA") && (
              <LoginOtp setError={setError} />
            )}
          </div>
          <div style={{ width: "50%" }}>
            <LoginInfo />
          </div>
        </LoginContainer>
      </div>
    </div>
  );
};
export default Login;

const LoginContainer = styled.div`
  display: flex;
`;
