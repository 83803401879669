import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavigationContext } from "../../Contexts/NavigationContext";

const Menu: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = React.useContext(NavigationContext);
  return (
    <ul className="nav flex-column">
      <li className="nav-item">
        <a
          className="nav-link"
          href="/service-order/new"
          onClick={(e) => {
            e.preventDefault();
            navigate("/service-order/new");
          }}
        >
          {t("module.kst.tickets.ticket")}
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="/service-orders/list"
          onClick={(e) => {
            e.preventDefault();
            navigate("/service-orders/list");
          }}
        >
          {t("module.kst.tickets")}
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="/reporters/list"
          onClick={(e) => {
            e.preventDefault();
            navigate("/reporters/list");
          }}
        >
          {t("module.kst.reporters")}
        </a>
      </li>
    </ul>
  );
};
export default Menu;
