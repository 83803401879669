import * as React from "react";
import styled from "styled-components";

const HeaderStyleButton: React.FC<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};
export default HeaderStyleButton;

const StyledButton = styled.button`
  background: none;
  border: none;
  color: #ddd;
  font-weight: bold;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  margin: 0;
  outline: 0;
  padding: 12px 8px;
  &:hover {
    color: #fff;
  }
`;
