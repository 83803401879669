import * as React from "react";
import { PropsWithChildren } from "react";

interface LayoutState {
  breadLinks: string[];
  breadTexts: string[];
  classNames: string[];
  title: string;
}
interface LayoutContextValue {
  layoutState: LayoutState;
  setLayoutState: React.Dispatch<React.SetStateAction<LayoutState>>;
}

export const LayoutContext = React.createContext<LayoutContextValue>({
  layoutState: { breadLinks: [], breadTexts: [], classNames: [], title: "" },
  setLayoutState: () => undefined,
});

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const [layoutState, setLayoutState] = React.useState<LayoutState>({
    breadLinks: [],
    breadTexts: [],
    classNames: [],
    title: "",
  });
  const contextValue = React.useMemo<LayoutContextValue>(
    () => ({
      setLayoutState,
      layoutState,
    }),
    [layoutState]
  );
  React.useEffect(() => {
    if (layoutState.title) {
      document.title = `${layoutState.title}`;
    }
  }, [layoutState.title]);
  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};
export default Provider;
