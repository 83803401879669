import axios from "axios";
import i18next from "i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import queryClient from "./queryClient";

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: false,
    backend: {
      loadPath: `/api/localization/{{lng}}`,
    },
    parseMissingKeyHandler: (key) => `{${key}}`,
    supportedLngs: ["en", "sv"],
    detection: {
      caches: [],
      order: ["htmlTag"],
    },
    react: { useSuspense: true },
  })
  .catch((res) => console.error(res));

i18next.on("languageChanged", async (lng) => {
  try {
    document.documentElement.setAttribute("lang", lng);
    await axios.post(`/api/localization?locale=${lng}`);
    await queryClient.refetchQueries({ type: "active" });
    await queryClient.invalidateQueries({ type: "inactive" });
  } catch (e) {
    console.log(e);
  }
});

export default i18n;
