import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const post = async (user: string) => {
  await axios.post("/api/recover", null, { params: { user } });
};

const useSendRecover = () => {
  const { mutateAsync, isPending } = useMutation({ mutationFn: post });

  return { sendRecover: mutateAsync, isPending };
};
export default useSendRecover;
