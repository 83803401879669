import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface NavigationContextValue {
  navigate: (to: string) => void;
  setConfirmNavigate: (forceConfirm: boolean) => void;
}

export const NavigationContext = React.createContext<NavigationContextValue>({
  navigate: () => undefined,
  setConfirmNavigate: () => undefined,
});

const NavigationContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const confirmOnNavigate = React.useRef(false);
  const navigate = useNavigate();
  const contextValue = React.useMemo(() => {
    const confirmNavigateFn = (to: string) => {
      if (
        !confirmOnNavigate.current ||
        window.confirm(t("module.kst.tickets.ticket.confirm"))
      ) {
        confirmOnNavigate.current = false;
        navigate(to);
      }
    };

    const setConfirmNavigate = (forceConfirm: boolean) => {
      confirmOnNavigate.current = forceConfirm;
    };

    return { navigate: confirmNavigateFn, setConfirmNavigate };
  }, [navigate, t]);

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};
export default NavigationContextProvider;
