import { useSuspenseQuery } from "@tanstack/react-query";
import axios from "axios";

export interface OtpModel {
  email: string;
}

const get = async () => {
  const { data } = await axios.get<OtpModel>("/api/otp");
  return data;
};

const useOtp = () =>
  useSuspenseQuery({ queryFn: () => get(), queryKey: ["otp-model"] });
export default useOtp;
