import React, { PropsWithChildren } from "react";
import { LayoutContext } from "../../Contexts/LayoutContext";

const MainPage: React.FC<PropsWithChildren> = ({ children }) => {
  const { layoutState } = React.useContext(LayoutContext);
  return (
    <div className={`main-page ${layoutState.classNames.join(" ")}`}>
      {children}
    </div>
  );
};
export default MainPage;
