import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { color, H1, Text } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";

interface Props {
  status: number;
  message?: string;
}

const ErrorPage: React.FC<Props> = ({ status, message }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { reset } = useQueryErrorResetBoundary();
  const firstRenderRef = React.useRef(true);
  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    reset();
    return () => {
      reset();
    };
  }, [location, reset]);
  return (
    <div className="main-body">
      <div className="main-content">
        <Contents>
          <Hero>{status > 0 ? status : ""}</Hero>
          {status > 0 && <H1>{t(`error.${status}.header`)}</H1>}
          <div>
            <Text xl>
              {status > 0
                ? t(`error.${status}.message`)
                : t("error.500.message")}
            </Text>
          </div>
          {message && (
            <div>
              <Text xl>{message}</Text>
            </div>
          )}
          <div>
            <ErrorLocationText>{window.location.href}</ErrorLocationText>
          </div>
        </Contents>
      </div>
    </div>
  );
};
export default ErrorPage;

const Contents = styled.div`
  text-align: center;
  margin: 100px 16px;
`;

const Hero = styled(H1)`
  font-style: normal;
  font-weight: normal;
  font-size: 188px;
  line-height: 188px;
  color: ${color.BRAND_PREMIUM_GREY};
`;

const ErrorLocationText = styled(Text)`
  text-align: center;
  word-break: break-all;
  max-width: 600px;
`;
