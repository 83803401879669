import * as React from "react";
import useSession from "../api/session/useSession";
import { LoadingTextSuspenseLoader } from "../Components/SuspenseBoundary/SuspenseLoaders";

interface SessionContextProps {
  authenticated: boolean;
  user: string;
  roles: string[];
}

export const SessionContext = React.createContext<SessionContextProps>({
  authenticated: false,
  user: "",
  roles: [],
});

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [version, setVersion] = React.useState<string>("");
  const [login, setLogin] = React.useState<string>("");
  const { data, refetch } = useSession();

  const onFocus = React.useCallback(() => {
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (data.user && data.user !== login) {
      if (login) {
        // If it is a different user
        window.location.href = "/";
        return;
      }
      setLogin(data.user);
    }

    // Reload page if app version changed
    setVersion(data.version);
    if (version && version !== data.version) {
      window.location.reload();
      return;
    }
  }, [data, login, version]);

  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  const contextValue = React.useMemo<SessionContextProps>(() => {
    return {
      authenticated: Boolean(data && data.roles.includes("ROLE_USER")),
      user: data?.user ?? "",
      roles: data?.roles || [],
    };
  }, [data]);
  if (!data) {
    return <LoadingTextSuspenseLoader />;
  }
  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};
export default Provider;
