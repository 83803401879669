import * as React from "react";
import { useTranslation } from "react-i18next";
import useLogout from "../../api/auth/useLogout";
import { NavigationContext } from "../../Contexts/NavigationContext";
import { SessionContext } from "../../Contexts/SessionContext";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import HeaderStyleButton from "../Buttons/HeaderStyleButton";
import Menu from "../Menu/Menu";

const Header: React.FC = () => {
  const { authenticated } = React.useContext(SessionContext);
  const { navigate } = React.useContext(NavigationContext);
  const { logout } = useLogout();
  const { t } = useTranslation();
  return (
    <div className="main-head">
      <div className="nav-fixed-top">
        <div className="nav clear">
          {authenticated && (
            <>
              <Menu />
              <ul className="pull-right">
                <li>
                  <a
                    href={`/api/doc/portal-manual.pdf`}
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    {t("general.doc")}
                  </a>
                </li>
                <li>
                  <HeaderStyleButton onClick={async () => logout()}>
                    {t("general.logout")}
                  </HeaderStyleButton>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
      <div className="banner">
        <span>
          <a
            className="logo"
            data-testid={"home-link"}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            &nbsp;
          </a>
        </span>
        {authenticated && <Breadcrumbs />}
      </div>
    </div>
  );
};
export default Header;
