import * as React from "react";
import { LoadingTextSuspenseLoader } from "./SuspenseLoaders";

interface SuspenseBoundaryProps {
  fallback?: React.SuspenseProps["fallback"];
}
const SuspenseBoundary: React.FC<
  React.PropsWithChildren<SuspenseBoundaryProps>
> = ({ fallback, children }) => {
  return (
    <React.Suspense fallback={fallback || <LoadingTextSuspenseLoader />}>
      {children}
    </React.Suspense>
  );
};
export default SuspenseBoundary;
