import * as React from "react";
import styled from "styled-components";

const NoStyleButton: React.FC<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};
export default NoStyleButton;

const StyledButton = styled.button`
  background: none;
  border: none;
`;
