import { Button, Flex } from "@tele2/t2-ui-kit";
import Launch from "@tele2/t2-ui-kit/build/Icon/icons/LaunchOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LayoutContext } from "../../Contexts/LayoutContext";

export const openCookieBanner = () => {
  try {
    window.OneTrust.ToggleInfoDisplay();
  } catch (e) {
    console.error(e);
  }
};
export const initCookiePolicy = () =>
  window.OneTrust.initializeCookiePolicyHtml();

const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();
  const { setLayoutState } = React.useContext(LayoutContext);
  React.useEffect(() => {
    setLayoutState({
      breadLinks: [],
      breadTexts: [],
      classNames: [],
      title: `${t("module.cookie.policy")} - ${t("site.name")}`,
    });
  }, [setLayoutState, t]);
  React.useEffect(() => {
    try {
      if (window.OneTrust) {
        initCookiePolicy();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <HeaderRowWrapper>
        <Button secondary onClick={() => navigate(-1)}>
          {t("general.back")}
        </Button>
      </HeaderRowWrapper>
      <H1Wrapper>
        <h1>{t("module.cookie.policy")}</h1>
      </H1Wrapper>
      <p
        dangerouslySetInnerHTML={{
          __html: t("module.cookie.ingress"),
        }}
      />
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Button onClick={openCookieBanner} data-testid="cookie-policy-settings">
          {t("module.cookie.settings")}
          <Launch sm marginLeft={"xs"} />
        </Button>
      </Flex>
      <div id="ot-sdk-cookie-policy" />
    </PageWrapper>
  );
};

export default CookiePolicy;

const H1Wrapper = styled.div`
  text-align: center;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageWrapper = styled.div`
  margin: 24px auto;
  border-radius: 4px;
  max-width: 960px;
  padding: 24px;
  background-color: #ffffff;
`;
