import * as React from "react";
import { getEnv } from "../utils/utils";

declare global {
  interface Window {
    OneTrust: {
      OnConsentChanged: (e: () => void) => void;
      ToggleInfoDisplay: () => void;
      initializeCookiePolicyHtml: () => void;
      changeLanguage: (language: string) => void;
    };
    OptanonWrapper: () => void;
  }
}

const callbacks: { [key: number]: () => void } = {};

const useCookies = () => {
  React.useEffect(() => {
    const addListener = () => {
      if (window.OneTrust) {
        try {
          window.OneTrust.OnConsentChanged(() => {
            Object.entries(callbacks).forEach((c) => c[1]());
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        setTimeout(addListener, 250);
      }
    };
    const env = getEnv();
    if (["local", "test", "dev", "stage", "prod"].includes(env)) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.OptanonWrapper = () => {};
      const script = document.createElement("script");
      script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      script.type = "text/javascript";
      script.dataset.domainScript = `02cd8f5e-85fe-43e2-aea9-8f907c5d0633${
        ["local", "test", "dev", "stage"].includes(env) ? "-test" : ""
      }`;
      script.dataset.language = (
        document.documentElement.lang ||
        navigator.language ||
        "en"
      ).substring(0, 2);
      script.onload = addListener;
      document.body.appendChild(script);
    }
  }, []);
};
export default useCookies;

export const useCookieWatcher = (name: string): boolean => {
  const [state, setState] = React.useState<boolean>(cookieContains(name));
  React.useEffect(() => {
    const rand = Math.floor(Math.random() * 1000000);
    callbacks[rand] = () => setState(cookieContains(name));
    return () => {
      delete callbacks[rand];
    };
  }, [name]);
  return state;
};

export const useAcceptStatistics = () => useCookieWatcher("C0002%3A1");

const cookieContains = (value: string): boolean => {
  const regex = /OptanonConsent=([^;]+)/;
  const matches = regex.exec(document.cookie);
  const cookieValue = matches !== null ? decodeURI(matches[1]) : "false";
  return cookieValue.includes(value);
};
