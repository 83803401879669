import { Flex, Text } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useOtp from "../../api/auth/useOtp";
import useSendOtp, { SendOtpModel } from "../../api/auth/useSendOtp";
import Loader from "../Loader/Loader";
import LoginOtpForm from "./LoginOtpForm";

interface Props {
  setError: (error: string) => void;
}

const LoginOtp: React.FC<Props> = ({ setError }) => {
  const { t } = useTranslation();
  const { data: otpOptions } = useOtp();
  const { sendOtp, isPending } = useSendOtp();
  const [otpModel, setOtpModel] = React.useState<SendOtpModel>();
  return (
    <>
      {!otpModel && (
        <form>
          <div>
            <label htmlFor="email">{`${t("module.login.email")}: `}</label>
            <Text lg> {otpOptions.email}</Text>
          </div>
          <div className="buttons">
            <Flex alignItems={"center"}>
              <button
                className="btn"
                disabled={isPending}
                onClick={async (e) => {
                  e.preventDefault();
                  const otpModel = await sendOtp();
                  if (otpModel.error) {
                    setError(otpModel.error);
                  } else {
                    setOtpModel(otpModel);
                  }
                }}
              >
                {t("module.login.send.otp")}
              </button>
              <Loader isLoading={isPending} />
            </Flex>
          </div>
        </form>
      )}
      {otpModel && (
        <LoginOtpForm
          timeout={otpModel.timeout}
          resetOtpForm={() => setOtpModel(undefined)}
          setError={setError}
        />
      )}
    </>
  );
};
export default LoginOtp;
