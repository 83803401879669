import { Flex } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useLoginOtp from "../../api/auth/useLoginOtp";
import Loader from "../Loader/Loader";

interface Props {
  timeout: number;
  resetOtpForm: () => void;
  setError: (error: string) => void;
}

const LoginOtpForm: React.FC<Props> = ({ timeout, resetOtpForm, setError }) => {
  const { t } = useTranslation();
  const { loginOtp, isPending } = useLoginOtp();
  const [otpCode, setOtpCode] = React.useState<string>();
  const [otpTimeout, setOtpTimeout] = React.useState(timeout);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (otpTimeout > 0) {
        setOtpTimeout(otpTimeout - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [otpTimeout]);
  return (
    <>
      <form>
        <div className="flow-input-container">
          <label htmlFor="otp">{t("module.login.password")}</label>
          <input
            style={{ width: "220px" }}
            type="text"
            id="otp"
            name="otp"
            autoComplete="one-time-code"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        </div>
        <div className="buttons">
          <Flex alignItems={"center"}>
            <button
              className="btn"
              disabled={!otpCode || isPending}
              onClick={async (e) => {
                e.preventDefault();
                if (otpCode) {
                  const error = await loginOtp({ otpCode });
                  setError(error ? "module.login.otp.wrong.code" : "");
                }
              }}
            >
              {t("module.login.login")}
            </button>
            {timeout - otpTimeout > 30 && (
              <button
                className="btn"
                onClick={() => resetOtpForm()}
                type="button"
              >
                {t("module.login.otp.send.new.otp")}
              </button>
            )}
            <Loader isLoading={isPending} />
          </Flex>
        </div>
      </form>
      <p>
        {otpTimeout > 0 && (
          <span>
            <span>{t("module.login.otp.timeout")}</span>
            <span>{otpTimeout}</span>
          </span>
        )}
      </p>
    </>
  );
};
export default LoginOtpForm;
