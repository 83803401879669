import * as React from "react";
import { useTranslation } from "react-i18next";
import useLogout from "../../api/auth/useLogout";
import { NavigationContext } from "../../Contexts/NavigationContext";
import { SessionContext } from "../../Contexts/SessionContext";
import { getEnv } from "../../utils/utils";
import LinkStyleButton from "../Buttons/LinkStyleButton";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { authenticated, user } = React.useContext(SessionContext);
  const { navigate } = React.useContext(NavigationContext);
  const { logout } = useLogout();
  return (
    <div className="main-foot">
      <div className="vendor pull-right"></div>
      <div>
        <ul>
          <li>
            {authenticated && (
              <>
                {`${t("general.logged.in.as", { user })}`}
                {" | "}
                <LinkStyleButton onClick={() => logout()}>
                  {t("general.logout")}
                </LinkStyleButton>
                {" | "}
              </>
            )}
            <LinkStyleButton className="settings-link">
              {t("module.cookie.settings")}
            </LinkStyleButton>
            {" | "}
            <a
              href="/cookiepolicy"
              onClick={(e) => {
                e.preventDefault();
                navigate("/cookiepolicy");
              }}
            >
              {t("module.cookie.policy")}
            </a>
          </li>
        </ul>
        <LanguageSelector />
        {getEnv() !== "prod" && (
          <ul>
            <li className="important">{`${getEnv()} System`}</li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default Footer;
