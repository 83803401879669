import { Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const LoadingTextSuspenseLoader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LoaderContainer>
      <Loader size={100} borderWidth={14} loadingText={t("general.loading")} />
    </LoaderContainer>
  );
};

export const SuspenseLoader: React.FC = () => {
  // This component should NOT use useTranslation!
  return (
    <LoaderContainer>
      <Loader size={100} borderWidth={14} />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
