import * as React from "react";
import { useTranslation } from "react-i18next";

const LoginInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("module.login.ingress")}</p>
      <p
        style={{ color: "RED" }}
        dangerouslySetInnerHTML={{ __html: t("module.login.ingress.info.1") }}
      />
    </>
  );
};
export default LoginInfo;
