import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useInvalidateSession } from "../session/useSession";

interface LoginParams {
  username: string;
  password: string;
  rememberMe: boolean;
}

const post = async (params: LoginParams) => {
  const formData = new FormData();
  formData.set("password", params.password);
  formData.set("username", params.username);
  formData.set("remember_me", String(params.rememberMe));
  await axios.post("/api/security_check", formData);
};

const useLoginUsername = () => {
  const invalidateSession = useInvalidateSession();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: post,
    throwOnError: false,
  });
  const loginUsername = useCallback(
    async (params: LoginParams) => {
      try {
        await mutateAsync(params);
        await invalidateSession();
        return false;
      } catch (error) {
        return true;
      }
    },
    [invalidateSession, mutateAsync]
  );
  return { loginUsername, isPending };
};
export default useLoginUsername;
