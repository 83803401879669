import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useRemoveAllData } from "../session/useSession";

const post = async () => {
  await axios.post("/api/logout");
};

const useLogout = () => {
  const removeAllData = useRemoveAllData();
  const { mutateAsync } = useMutation({ mutationFn: post });
  const logout = useCallback(async () => {
    await mutateAsync();
    await removeAllData();
  }, [removeAllData, mutateAsync]);
  return { logout };
};
export default useLogout;
