import * as React from "react";
import { Route, Routes } from "react-router";
import AuthenticatedRoute from "./Components/AuthenticatedRoute/AuthenticatedRoute";
import Layout from "./Components/Layout/Layout";
import CookiePolicy from "./Routes/CookiePolicy/CookiePolicy";
import PageNotFound from "./Routes/Error/PageNotFound";
import Home from "./Routes/Home/Home";

const NewServiceOrder = React.lazy(
  () => import("./Routes/ServiceOrders/NewServiceOrder/NewServiceOrder")
);
const CreatedServiceOrder = React.lazy(
  () => import("./Routes/ServiceOrders/NewServiceOrder/CreatedServiceOrder")
);
const ServiceOrderView = React.lazy(
  () => import("./Routes/ServiceOrders/ServiceOrder/ServiceOrderView")
);
const ServiceOrderInline = React.lazy(
  () => import("./Routes/ServiceOrders/ServiceOrder/ServiceOrderInline")
);
const ReportersList = React.lazy(
  () => import("./Routes/Reporters/ReportersList")
);
const ServiceOrdersList = React.lazy(
  () => import("./Routes/ServiceOrders/ServiceOrderList/ServiceOrdersList")
);

const Verify = React.lazy(() => import("./Routes/Verify/Verify"));

const AppRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route
        path={"/service-orders/inline/:id"}
        element={<AuthenticatedRoute element={<ServiceOrderInline />} />}
      />
      <Route path="/" element={<Layout />}>
        <Route path={"/:id/verify"} element={<Verify />} />
        <Route
          path={"/service-order/new"}
          element={<AuthenticatedRoute element={<NewServiceOrder />} />}
        />
        <Route
          path={"/service-order/new/:id"}
          element={<AuthenticatedRoute element={<CreatedServiceOrder />} />}
        />
        <Route
          path={"/service-orders/:id/view"}
          element={<AuthenticatedRoute element={<ServiceOrderView />} />}
        />
        <Route
          path={"/service-orders/list"}
          element={<AuthenticatedRoute element={<ServiceOrdersList />} />}
        />
        <Route
          path={"/reporters/list"}
          element={<AuthenticatedRoute element={<ReportersList />} />}
        />
        <Route path="/cookiepolicy" element={<CookiePolicy />} />
        <Route path="/login" element={<Home />} />
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
export default AppRoutes;
