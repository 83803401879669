import * as React from "react";
import styled from "styled-components";

const LinkStyleButton: React.FC<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};
export default LinkStyleButton;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #1067d0;
  &:hover {
    text-decoration: underline;
  }
`;
