import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import AppRoutes from "./AppRoutes";
import ErrorHandler from "./Components/ErrorHandler/ErrorHandler";
import SuspenseBoundary from "./Components/SuspenseBoundary/SuspenseBoundary";
import { SuspenseLoader } from "./Components/SuspenseBoundary/SuspenseLoaders";
import useApm from "./hooks/useApm";
import useCookies from "./hooks/useCookies";
import useGtm from "./hooks/useGtm";
import NavigationContext from "./Contexts/NavigationContext";
import SessionContext from "./Contexts/SessionContext";

function App() {
  useCookies();
  useApm();
  useGtm();
  return (
    <SuspenseBoundary fallback={<SuspenseLoader />}>
      <BrowserRouter>
        <GlobalStyle />
        <ErrorHandler>
          <SessionContext>
            <SuspenseBoundary>
              <NavigationContext>
                <AppRoutes />
              </NavigationContext>
            </SuspenseBoundary>
          </SessionContext>
        </ErrorHandler>
      </BrowserRouter>
    </SuspenseBoundary>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${color.BRAND_WHITE};
  }
`;
