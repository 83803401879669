import { Modal } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useSendRecover from "../../api/recover/useSendRecover";
import Loader from "../Loader/Loader";

interface Props {
  onClose: () => void;
}

const RecoverModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { sendRecover, isPending } = useSendRecover();
  const [state, setState] = React.useState({
    sent: false,
    recoverUser: "",
  });
  return (
    <Modal onClose={onClose} size={"md"} title={t("module.recover")}>
      <div className="popup">
        {state.sent && (
          <>
            <div className="alert alert-success">
              {t("module.recover.sent")}
            </div>
          </>
        )}
        {!state.sent && (
          <>
            <p>{t("module.recover.ingress")}</p>
            <div className="mf-form">
              <div className="mf-section">
                <div className="mf-text">
                  <label htmlFor="user">{t("module.recover.user")}</label>
                  <input
                    type="text"
                    name="user"
                    id="user"
                    value={state.recoverUser}
                    onChange={(e) =>
                      setState({ ...state, recoverUser: e.target.value })
                    }
                  />
                </div>
                <div className="mf-submit">
                  <Loader isLoading={isPending} />
                  <button
                    className="btn"
                    disabled={!state.recoverUser || isPending}
                    onClick={async () => {
                      await sendRecover(state.recoverUser);
                      setState({ recoverUser: "", sent: true });
                    }}
                  >
                    {t("module.recover.send")}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
export default RecoverModal;
