import * as React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation();

  const availLngs = i18n.options.supportedLngs
    ? i18n.options.supportedLngs?.filter((lang: string) => {
        return lang !== "cimode";
      })
    : [];

  return (
    <div>
      <select
        name="lang"
        id="language-selector"
        onChange={(e) => {
          onLanguageChange(e.target.value);
          i18n.changeLanguage(e.target.value);
        }}
        value={i18n.language}
      >
        {availLngs?.map((lang) => (
          <option key={lang} value={lang}>
            {t(`general.language.${lang}`)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
const onLanguageChange = (language: string) => {
  if (window.OneTrust) {
    try {
      window.OneTrust.changeLanguage(language);
    } catch (e) {
      console.error(e);
    }
  }
};
