import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import { Outlet } from "react-router";
import styled from "styled-components";
import LayoutContext from "../../Contexts/LayoutContext";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MainPage from "./MainPage";

const Layout: React.FC = () => {
  return (
    <LayoutContext>
      <Background>
        <MainPage>
          <Header />
          <Outlet />
          <Footer />
        </MainPage>
      </Background>
    </LayoutContext>
  );
};
export default Layout;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  body {
    background: ${color.BRAND_WHITE};
  }
`;
